
body {

  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: #000;


}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    position: relative;
}
li, ol {
  list-style: none; }

a:link, a:visited, a:hover, a:active {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

input, button, select, option {
  background-clip: padding-box;
  border: none; }

input:focus, textarea:focus, select:focus, button:focus {
  outline: 0; }

h1,h2{
    position: absolute;
    visibility: hidden;
    top: -100%;
}
