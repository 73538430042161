
.bg_fixed{
    background-image: url('./media/bg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    height: 100vh;
    left: 0;
    position: fixed;
    top:0;
    width: 100vw;
    opacity: .5;
}
section{
    min-height: 100vh;
    display: grid;
    align-content: center;
    padding: 15rem 0;
}
section.black{
    background: rgba(0,0,0,.8);
    color: #fff;
}
section.white{
    background: rgba(240,240,240,.8);
    color: #000;
}
.wrapper{
    width: 80rem;
    margin: auto;
}
.title{
    font-size: 4rem;
    font-weight: bold;
}
.subtitle{
    font-size: 2rem;
    font-weight: bold;
    margin: 5rem 0 4rem;
    text-align: left;
    line-height: 1.4;

}
p{
    font-size: 1.6rem;
    line-height: 1.4;
    margin: 1rem 0;
    text-align: left;
    margin: 1rem 0;
}
.p_intro{
    margin: 4rem 0;
    font-size: 1.8rem;
}
.btn{
    background:#678964;
    padding: 2rem 4rem;
    font-size: 1.6rem;
    font-weight: bold;
    display: inline-block;
    margin: 5rem auto 0;
}
.mobile{
    display: none;
}
@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1024px) {
    .wrapper{
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {

    .mobile{
        display: block;
    }
}

@media only screen and (max-width: 480px) {
    .title{
        font-size: 3rem;
    }
    .subtitle{
        font-size: 1.7rem;
    }
    .p_intro{
        font-size: 1.6rem;
    }
}
