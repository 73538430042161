
.Top img{
    margin: 7rem auto 2rem;
    width: 40rem;
}
.Top .title{
    font-size: 2.2rem;
    line-height: 1.5;
    font-weight: 400;
    text-transform: uppercase;

}
.Top .subtitle{
    text-align: center;
    margin-top: 1rem;
    font-size: 1.7rem;
}

.Nav{
    position: absolute;
    top: 5rem;
    width: 100%;
}

.Nav a{
    font-size: 1.7rem;
    padding: 0 2rem;
}

@media only screen and (max-width: 1300px) {
    .Top img{
        width: 35rem;
    }
}

@media only screen and (max-width: 1024px) {

    .Top img{
        width: 30rem;
    }
}

@media only screen and (max-width: 768px) {
    .Top .title{
        font-size: 2rem;
    }
    .Nav a{
        font-size: 1.7rem;
        padding: 0 1rem;
    }
}

@media only screen and (max-width: 480px) {

    .Top .title{
        font-size: 1.6rem;
    }
    .Top img{
        width: 90%;
    }
    .Nav a{
        font-size: 1.4rem;
        padding: 0 .8rem;
    }
}
