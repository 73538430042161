.Contact p{
    text-align: center;
    font-weight: bold;
    font-size: 2.1rem;
}
.Contact .title{
    margin-bottom: 8rem;

}
.Contact .subtitle{
    text-align: center;
    margin: 5rem auto 0rem;
    font-size: 5.5rem;
    line-height: 1.1;

}

.mail{
    height: 3rem;
    top: .8rem;
    margin-right: 1rem;
}
.linkedin{
    height: 4rem;
}
footer{
    position: absolute;
    bottom: 4rem;
    width: 100%;
    font-size: 1.4rem;
}
@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 480px) {
    .Contact .subtitle{
        font-size: 4.2rem;
        line-height: 1.1;

    }
    .Contact p{
        font-size: 2rem;
    }
    .mail{
        display: none;
    }
    .linkedin{
        height: 3.5rem;
        margin-top: 1rem;
    }
}
