.About{
    padding-bottom: 20rem;
}

.About p{
        text-align: justify;

}
@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 480px) {

}
